import Vue from 'vue';
import VueRouter from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        resource: 'All',
        action: 'read',
      },
    },
    {
      path: '/auftraege',
      name: 'auftraege',
      component: () => import('@/views/Auftraege.vue'),
      meta: {
        pageTitle: 'Aufträge',
        breadcrumb: [
          {
            text: 'Aufträge',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/details/:id',
      name: 'auftrag-details',
      component: () => import('@/views/Auftraege/AuftragDetails.vue'),
      meta: {
        pageTitle: 'Auftrag Details',
        navActiveLink: 'auftraege',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag Details',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/bearbeiten/:id',
      name: 'auftrag-bearbeiten',
      component: () => import('@/views/Auftraege/AuftragBearbeiten.vue'),
      meta: {
        pageTitle: 'Auftrag bearbeiten',
        navActiveLink: 'auftraege',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/abarbeiten/:id',
      name: 'auftrag-abarbeiten',
      component: () => import('@/views/Auftraege/AuftragAbarbeiten.vue'),
      meta: {
        pageTitle: 'Auftrag abarbeiten',
        navActiveLink: 'home',
        breadcrumb: [
          {
            text: 'Auftrag abarbeiten',
            active: true,
          },
        ],
        resource: 'AdminMitarbeiter',
        action: 'read',
      },
    },
    {
      path: '/auftrag/tagesbericht/erstellen/:id',
      name: 'tagesbericht-erstellen',
      component: () =>
        import('@/views/Tagesberichte/TagesberichtErstellen.vue'),
      meta: {
        pageTitle: 'Bericht erstellen',
        breadcrumb: [
          {
            text: 'Aufträge',
            active: true,
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag',
            active: true,
          },
          {
            text: 'Bericht erstellen',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/tagesbericht/vorschau/:id',
      name: 'tagesbericht-vorschau',
      component: () => import('@/views/Tagesberichte/TagesberichtVorschau.vue'),
      meta: {
        pageTitle: 'Bericht Vorschau',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            active: true,
            text: 'Auftrag',
          },
          {
            active: true,
            text: 'Bericht Vorschau',
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/tagesbericht/bearbeiten/:id',
      name: 'tagesbericht-bearbeiten',
      component: () =>
        import('@/views/Tagesberichte/TagesberichtBearbeiten.vue'),
      meta: {
        pageTitle: 'Bericht bearbeiten',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag',
            active: true,
          },
          {
            text: 'Bericht bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/rechnung/erstellen/:id',
      name: 'rechnung-erstellen',
      component: () => import('@/views/Rechnungen/RechnungErstellen.vue'),
      meta: {
        pageTitle: 'Rechnung erstellen',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag',
            active: true,
          },
          {
            text: 'Rechnung erstellen',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/auftragsbestaetigung/:id',
      name: 'auftragsbestaetigung',
      component: () =>
        import('@/views/Auftraege/AuftragsbestaetigungVorschau.vue'),
      meta: {
        pageTitle: 'Auftragsbestätigung',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftragsbestätigung',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/rechnung/erstellen',
      name: 'manuelle-rechnung-erstellen',
      component: () => import('@/views/Rechnungen/RechnungErstellen.vue'),
      meta: {
        pageTitle: 'Rechnung erstellen',
        breadcrumb: [
          {
            text: 'Rechnungen',
            to: { name: 'rechnungen' },
          },
          {
            text: 'Rechnung erstellen',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/rechnung/vorschau/:id',
      name: 'rechnung-vorschau',
      component: () => import('@/views/Rechnungen/RechnungVorschau.vue'),
      meta: {
        pageTitle: 'Vorschau Rechnung',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            active: true,
            text: 'Auftrag',
          },
          {
            active: true,
            text: 'Vorschau Rechnung',
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/rechnung/vorschau/:id',
      name: 'manuelle-rechnung-vorschau',
      component: () => import('@/views/Rechnungen/RechnungVorschau.vue'),
      meta: {
        pageTitle: 'Vorschau Rechnung',
        breadcrumb: [
          {
            text: 'Rechnungen',
            to: { name: 'rechnungen' },
          },
          {
            active: true,
            text: 'Vorschau Rechnung',
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/auftrag/rechnung/bearbeiten/:id',
      name: 'rechnung-bearbeiten',
      component: () => import('@/views/Rechnungen/RechnungBearbeiten.vue'),
      meta: {
        pageTitle: 'Rechnung bearbeiten',
        breadcrumb: [
          {
            text: 'Aufträge',
            to: { name: 'auftraege' },
          },
          {
            text: 'Auftrag',
            active: true,
          },
          {
            text: 'Rechnung bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/rechnung/bearbeiten/:id',
      name: 'manuelle-rechnung-bearbeiten',
      component: () => import('@/views/Rechnungen/RechnungBearbeiten.vue'),
      meta: {
        pageTitle: 'Rechnung bearbeiten',
        breadcrumb: [
          {
            text: 'Rechnungen',
            to: { name: 'rechnungen' },
          },
          {
            active: true,
            text: 'Rechnung bearbeiten',
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/rechnungen',
      name: 'rechnungen',
      component: () => import('@/views/Rechnungen.vue'),
      meta: {
        pageTitle: 'Rechnungen',
        breadcrumb: [
          {
            text: 'Rechnungen',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/kunden',
      name: 'kunden',
      component: () => import('@/views/Kunden.vue'),
      meta: {
        pageTitle: 'Kunden',
        breadcrumb: [
          {
            text: 'Kunden',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/kunde/ansicht/:id',
      name: 'kunde-ansicht',
      component: () => import('@/views/Kunden/KundeAnsicht.vue'),
      meta: {
        pageTitle: 'Kunde Ansicht',
        navActiveLink: 'kunde',
        breadcrumb: [
          {
            text: 'Kunden',
            to: { name: 'kunden' },
          },
          {
            text: 'Kunde',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/kunde/bearbeiten/:id',
      name: 'kunde-bearbeiten',
      component: () => import('@/views/Kunden/KundeBearbeiten.vue'),
      meta: {
        pageTitle: 'Kunde bearbeiten',
        navActiveLink: 'kunde',
        breadcrumb: [
          {
            text: 'Kunden',
            to: { name: 'kunden' },
          },
          {
            text: 'Kunde bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter',
      name: 'mitarbeiter',
      component: () => import('@/views/Mitarbeiter.vue'),
      meta: {
        pageTitle: 'Mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter/ansicht/:id',
      name: 'mitarbeiter-ansicht',
      component: () => import('@/views/Mitarbeiter/MitarbeiterAnsicht.vue'),
      meta: {
        pageTitle: 'Mitarbeiter Ansicht',
        navActiveLink: 'mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            to: { name: 'mitarbeiter' },
          },
          {
            text: 'Mitarbeiter ansicht',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter/bearbeiten/:id',
      name: 'mitarbeiter-bearbeiten',
      component: () => import('@/views/Mitarbeiter/MitarbeiterBearbeiten.vue'),
      meta: {
        pageTitle: 'Mitarbeiter bearbeiten',
        navActiveLink: 'mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            to: { name: 'mitarbeiter' },
          },
          {
            text: 'Mitarbeiter bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter/mitarbeiterbericht/erstellen',
      name: 'mitarbeiterbericht-erstellen',
      component: () =>
        import(
          '@/views/Mitarbeiter/Mitarbeiterberichte/MitarbeiterberichtErstellen.vue'
        ),
      meta: {
        pageTitle: 'Mitarbeiterbericht erstellen',
        navActiveLink: 'mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            to: { name: 'mitarbeiter' },
          },
          {
            text: 'Mitarbeiterbericht erstellen',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter/mitarbeiterbericht/vorschau/:id',
      name: 'mitarbeiterbericht-vorschau',
      component: () =>
        import(
          '@/views/Mitarbeiter/Mitarbeiterberichte/MitarbeiterberichtVorschau.vue'
        ),
      meta: {
        pageTitle: 'Vorschau Mitarbeiterbericht',
        navActiveLink: 'mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            to: { name: 'mitarbeiter' },
          },
          {
            text: 'Vorschau Mitarbeiterbericht',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/mitarbeiter/mitarbeiterbericht/bearbeiten/:id',
      name: 'mitarbeiterbericht-bearbeiten',
      component: () =>
        import(
          '@/views/Mitarbeiter/Mitarbeiterberichte/MitarbeiterberichtBearbeiten.vue'
        ),
      meta: {
        pageTitle: 'Mitarbeiterbericht bearbeiten',
        navActiveLink: 'mitarbeiter',
        breadcrumb: [
          {
            text: 'Mitarbeiter',
            to: { name: 'mitarbeiter' },
          },
          {
            text: 'Mitarbeiterbericht bearbeiten',
            active: true,
          },
        ],
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/account-erstellen',
      name: 'auth-account-erstellen',
      component: () => import('@/views/authentication/Account-Erstellen.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/nicht-autorisiert',
      name: 'nicht-autorisiert',
      component: () => import('@/views/authentication/NichtAutorisiert.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'All',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'All',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = localStorage.getItem('userData') !== null ? true : false;
  if (isLoggedIn && to.meta.redirectIfLoggedIn) {
    return next({ name: 'home' });
  }

  // If logged in => not authorized
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn && !to.name.includes('auth')) {
      return next({ name: 'auth-login' });
    }

    // Redirect if logged in but not authorized
    return next({ name: 'nicht-autorisiert' });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
